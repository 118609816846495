import React from 'react'
import './Spinner.css'

const Spinner = () => {
  return (
    <div className='dot-wave'>
      <div className='dot-wave__dot'></div>
      <div className='dot-wave__dot'></div>
      <div className='dot-wave__dot'></div>
      <div className='dot-wave__dot'></div>
    </div>
  )
}

export default Spinner
