import { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import AppRoutes from "./routing/AppRoutes";
import { ToastContainer } from "react-toastify";

// import { motion, useScroll } from "framer-motion";

function App() {
  // const { scrollYProgress } = useScroll();

  return (
    <div className="App">
      <AppRoutes />
      <ToastContainer />

    </div>
  );
}

export default App;
