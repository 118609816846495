import { UseCallApi } from "./CallApi"

export const getPays = async (set:any) => {
    try {
      const data = await UseCallApi()({
        route: 'api/pays.json',
        method: 'GET',
      })
      const modifiedData = data.map((item:any) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
  
      set(modifiedData)
    } catch (error) {
      console.error('Error fetching product data:', error)
      set([])
    }
  }