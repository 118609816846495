import React from "react";
import { NavLink } from "react-router-dom";
import "./Tab.css"; // Import your custom CSS file for styling

const Tab = () => {
  return (
    <div className="mb-5">
      <ul className="nav nav-tabs justify-content-end">
        <li className="nav-item custom-tab">
          <NavLink to="/auth/registration/rider" className="nav-link custom-link">
            Passager
          </NavLink>
        </li>
        <li className="nav-item custom-tab">
          <NavLink to="/auth/registration/driver" className="nav-link custom-link">
            Chauffeur
          </NavLink>
        </li>
        <li className="nav-item custom-tab">
          <NavLink to="/auth/registration/fleet" className="nav-link custom-link">
            Conducteur de flotte
          </NavLink>
        </li>
        <li className="nav-item custom-tab">
          <NavLink to="/auth/registration/organization" className="nav-link custom-link">
            Organisation
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Tab;
