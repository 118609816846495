import { ChangeEvent, FC, useState, useEffect } from "react";
import clsx from "clsx";
import LabelInput from "../../form/LabelInput";
import SearchSelect from "../../form/SearchSelect";
import { UseCallApi } from "../../../helpers/CallApi";
import { getPays } from "../../../helpers/Request";
import LabelTextArea from "../../form/LabelTextArea";
import { BsFillPencilFill } from "react-icons/bs";

type Props = {
  isUserLoading: boolean;
  formik?: any;
  image?: any;
  setImage?: any;
  setFiles?: any;
  filess?: any;
};

const Form1: FC<Props> = ({
  formik,
  isUserLoading,
  setImage,
  image,
  setFiles,
  filess,
}) => {
  const [languages, setLanguages] = useState([]);
  const [idPays, setIdPays] = useState<any>("null");
  const [idState, setIdState] = useState<any>("null");
  const [pays, setPays] = useState<any>([]);
  const [isLoadingStates, setIsLoadingStates] = useState(false);
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [companies, setCompanies] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const callApi = UseCallApi();

  const getLanguages = async () => {
    try {
      const data = await callApi({
        route: `api/languages.json`,
        method: "GET",
      });
      const modifiedData = data.map((item: any) => ({
        ...item,
        label: item.lang,
        value: item.id,
      }));
      setLanguages(modifiedData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };
  const getCompanies = async () => {
    try {
      const data = await callApi({
        route: `api/companies.json`,
        method: "GET",
      });
      const modifiedData = data.map((item: any) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
      setCompanies(modifiedData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };
  const getStates = async (id: number) => {
    try {
      setIsLoadingStates(true); // Set loading state to true
      const data = await callApi({
        route: `api/pays/${id}`,
        method: "GET",
      });

      const modifiedData = data.states.map((item: any) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
      // setCities([])
      setStates(modifiedData);
    } catch (error) {
      console.error("Error fetching state data:", error);
    } finally {
      setIsLoadingStates(false); // Set loading state back to false
    }
  };

  const getCities = async (id: number) => {
    try {
      setIsLoadingCities(true); // Set loading state to true
      const data = await callApi({
        route: `api/states/${id}`,
        method: "GET",
      });

      const modifiedData = data.cities.map((item: any) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));

      setCities(modifiedData);
    } catch (error) {
      console.error("Error fetching city data:", error);
    } finally {
      setIsLoadingCities(false); // Set loading state back to false
    }
  };

  useEffect(() => {
    getCompanies();
    getPays(setPays);
    getLanguages();
  }, []);
  useEffect(() => {
    if (formik.getFieldProps("country").value) {
      getStates(formik.getFieldProps("country").value);
    } else {
      setStates([]);
    }
  }, [idPays, formik.getFieldProps("country").value]);
  useEffect(() => {
    if (formik.getFieldProps("state").value != "null") {
      getCities(formik.getFieldProps("state").value);
    } else {
      setCities([]);
    }
  }, [idState, formik.getFieldProps("state").value]);
  useEffect(() => {
    formik.setFieldValue("paymentEmail", formik.getFieldProps("email").value);
  }, [formik.getFieldProps("email").value]);
  useEffect(() => {
    let name = "";
    if (formik.getFieldProps("nom").value) {
      name = name + "" + formik.getFieldProps("nom").value;
    }
    if (formik.getFieldProps("prenom").value) {
      name = name + "" + formik.getFieldProps("prenom").value;
    }
    formik.setFieldValue("AccountHolderName", name);
  }, [formik.getFieldProps("nom").value, formik.getFieldProps("prenom").value]);
  return (
    <div>
      <div className="row mb-3 d-flex">
        <div className=" col col-2">
          {" "}
          <label htmlFor="exampleInputEmail1" className="fw-bold">
            Photo :
          </label>
          <div className="form-group mb-5  ">
            <div
              className="image-input image-input-outline"
              data-kt-image-input="true"
              style={{
                backgroundImage: "url('/media/svg/avatar/blank.svg')",
              }}
            >
              <div
                id="image"
                className="image-input-wrapper w-125px h-125px shadow-sm border"
                style={{
                  backgroundImage:
                    filess == ""
                      ? "url('/media/svg/avatar/blank.svg')"
                      : "url(" + filess + ")",
                }}
              ></div>
              <label
                className="btn  w-10px h-10px rounded-circle   bg-body shadow color-secondary"
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                data-bs-dismiss="click"
                title="Change avatar"
              >
                <BsFillPencilFill  />
                <input
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files[0]) {
                      setFiles(URL.createObjectURL(e.target.files[0]));
                      setImage(Array.from(e.target.files));
                    }
                  }}
                  type="file"
                  name="photo"
                  accept=".png, .jpg, .jpeg"
                />
                <input type="hidden" name="avatar_remove" />
              </label>

              <span
                className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="cancel"
                data-bs-toggle="tooltip"
                data-bs-dismiss="click"
                title="Cancel avatar"
              >
                <i className="bi bi-x fs-2"></i>
              </span>
            </div>
          </div>
          {/* end::Image input */}
        </div>
        <div className=" col-sm-12 col-md-8 col-lg-10 row">
          <div className="col">
            <LabelInput
              label={"Nom"}
              type={"string"}
              formik={formik}
              isUserLoading={isUserLoading}
              placeholder={"Nom"}
              name={"nom"}
            />
          </div>
          <div className="col">
            <LabelInput
              label={"Prénom"}
              type={"string"}
              formik={formik}
              isUserLoading={isUserLoading}
              name={"prenom"}
              placeholder="Prénom"
            />
          </div>
          <div className="row mb-3 text-start">
            <label className="required fw-bold fs-6 mb-3 mt-3">Sexe</label>
            <div className="  d-flex ">
              <div className="me-5">
                <div className="d-flex fv-row">
                  <div className="form-check form-check-custom form-check-solid">
                    <input
                      className="form-check-input me-3"
                      {...formik.getFieldProps("sexe")}
                      name="sexe"
                      type="radio"
                      value="f"
                      id="f"
                      checked={formik.values.sexe === "f"}
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    <label className="form-check-label" htmlFor="f">
                      <div className="fw-bolder text-gray-800">Femme</div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="d-flex fv-row">
                  {/* begin::Radio */}
                  <div className="form-check form-check-custom form-check-solid">
                    {/* begin::Input */}
                    <input
                      className="form-check-input me-3"
                      {...formik.getFieldProps("sexe")}
                      name="sexe"
                      type="radio"
                      value="h"
                      id="h"
                      checked={formik.values.sexe === "h"}
                      disabled={formik.isSubmitting || isUserLoading}
                    />

                    <label className="form-check-label" htmlFor="h">
                      <div className="fw-bolder text-gray-800">Homme</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" row mb-3">
        <div className="col mb-4 text-start">
          <label htmlFor="country" className="required form-label">
            Pays
          </label>
          <SearchSelect
            options={pays}
            defaultValue={formik.values.country}
            name={"country"}
            formik={formik}
          />
        </div>
        <div className="col mb-3 text-start">
          <label htmlFor="state" className="required form-label">
            Province
          </label>
          <SearchSelect
            options={states}
            defaultValue={formik.values.state}
            name={"state"}
            formik={formik}
          />

          {isLoadingStates && <div>Loading Cities...</div>}
        </div>
        <div className="col text-start">
          <label htmlFor="city" className="required form-label">
            Ville
          </label>
          <SearchSelect
            options={cities}
            defaultValue={formik.values.city}
            name={"city"}
            formik={formik}
          />

          {isLoadingCities && <div>Loading Cities...</div>}
        </div>
        {/* start input zip code */}
        <div className="col mb-3">
          <LabelInput
            label={"Code postal"}
            type={"number"}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"zipCode"}
          />
        </div>
      </div>
      <div className="row">
        {/* begin::Input group */}
        <div className="col mb-3">
          <LabelInput
            label={"Téléphone"}
            type={"phone"}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"tel"}
            placeholder="Numéro de téléphone"
          />
        </div>
        <div className="col mb-3">
          <LabelInput
            label={"Email"}
            type={"email"}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"email"}
            placeholder="example@example.com"
          />
        </div>

        <div className="col">
          <LabelInput
            label={"Mots de passe"}
            type={"password"}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"password"}
          />
        </div>
        <div className="col">
          <LabelInput
            label={" Confirmer mot de passe"}
            type={"password"}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"confirmPassword"}
          />
        </div>
      </div>
      <div className="mb-3">
        <div className="row justify-content-start">
          <div className="col text-start ">
            <LabelTextArea
              formik={formik}
              name={"adress"}
              label={"Adresse"}
              isUserLoading={isUserLoading}
              placeholder={"Adresse"}
            />
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          {" "}
          <LabelInput
            label={"Entreprise"}
            type={"text"}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"company"}
          />
        </div>
        <div className="col">
          <LabelInput
            label={"Code Entreprise"}
            type={"text"}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"codeCompany"}
          />
        </div>
      </div>
      {/* <div className='Row mb-4'>
        <label htmlFor='country' className='form-label'>
          Entreprise
        </label>
        <SearchSelect
          options={companies}
          defaultValue={formik.values.company}
          name={'company'}
          formik={formik}
        />
       
      </div> */}
      <div className="row">
        <div className="col mb-3 text-start">
          <label htmlFor="language" className="form-label">
            Langue
          </label>
          <SearchSelect
            options={languages}
            defaultValue={formik.values.language}
            name={"language"}
            formik={formik}
          />
        </div>
        <div className="col mb-3 text-start">
          <label htmlFor="currency" className="form-label">
            Devise
          </label>
          <SearchSelect
            options={[
              { value: "eur", label: "EUR" },
              { value: "mad", label: "MAD" },
            ]}
            defaultValue={formik.values.currency}
            name={"currency"}
            formik={formik}
          />
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <LabelInput
            label={"Email de paiement"}
            type={"email"}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"paymentEmail"}
          />
        </div>

        <div className="col mb-3">
          <LabelInput
            label={"  Nom du titulaire du compte"}
            type={"name"}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"AccountHolderName"}
          />
        </div>
        <div className="col mb-3">
          <LabelInput
            label={"  Numéro de compte"}
            type={"text"}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"AccountNumber"}
          />
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <LabelInput
            label={"Nom de la banque"}
            type={"text"}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"BankName"}
          />
        </div>
        <div className="col mb-3">
          {/* begin::Label */}
          <LabelInput
            label={"Emplacement de la banque"}
            type={"text"}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"BankLocation"}
          />
        </div>
        <div className="col mb-3">
          <LabelInput
            label={"Code BIC/SWIFT"}
            type={"text"}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"BIC_SWIFT"}
          />
          {formik.touched.BIC_SWIFT && formik.errors.BIC_SWIFT && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.BIC_SWIFT}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
      </div>
    </div>
  );
};

export default Form1;
