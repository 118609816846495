import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/css/main.css";
import "./assets/css/image-input.css";
import { AppProvider } from "./AppContext";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppProvider>

      <App />
    </AppProvider>
  </React.StrictMode>
);
