import React, { useState, useEffect } from "react";
import { FC } from "react";
import Accordion from "../../Accordion/Accordion";
import { getFileExtension } from "../../../helpers/functions/GetFileExtension";

type Props = {
  form?: any;
  formik?: any;
  updateData?: any;
};

const Form2: FC<Props> = ({ formik, updateData, form }) => {
  return (
    <div>
      {form &&
        form.map((element: any, index: number) => (
          <div key={`${element.id}_${index}`}>
            <Accordion
              titre={element?.title}
              readonly={true}
              accordionId={`${element.id}_${index}`}
            >
              <div className="row">
                {element.elementFormDocuments.map((ele: any, ind: number) => (
                  <div className="col-6" key={`${element.id}_${ele.id}`}>
                    <div>
                      <label
                        htmlFor={`${element.id}_${ele.id}`}
                        className="form-label"
                      >
                        {ele.label}
                      </label>
                      {ele.type !== "file" ? (
                        <input
                          type={`${ele.type}`}
                          className="form-control"
                          id={`${ele.id}`}
                          name={`${ele.id}`}
                          value={
                            formik.values[ele.id]
                              ? formik.values[ele.id]
                              : updateData &&
                                updateData.paperDrivers &&
                                updateData.paperDrivers[index] &&
                                updateData.paperDrivers[index].elementsPapers &&
                                updateData.paperDrivers[index].elementsPapers[
                                  ind
                                ]
                              ? updateData?.paperDrivers[index]?.elementsPapers[
                                  ind
                                ]?.value
                              : ""
                          }
                          onChange={(event) => {
                            formik.setFieldValue(
                              `${ele.id}`,
                              ele.type === "file"
                                ? event?.target?.files?.[0]
                                : event?.target.value
                            );
                          }}
                        />
                      ) : (
                        <input
                          type={`${ele.type}`}
                          className="form-control"
                          id={`${ele.id}`}
                          name={`${ele.id}`}
                          onChange={(event) => {
                            formik.setFieldValue(
                              `${ele.id}`,
                              ele.type === "file"
                                ? event?.target?.files?.[0]
                                : event?.target.value
                            );
                          }}
                        />
                      )}
                    </div>
                    {ele.type === "file" && formik.values[ele.id] ? (
                      <div>
                        {(ele.type === "file" &&
                          formik.values[ele.id] &&
                          formik.values[ele.id].type === "application/pdf") ||
                        formik.values[ele.id].type === "application/msword" ||
                        formik.values[ele.id].type ===
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                          <div style={{ overflow: "hidden" }}>
                            <iframe
                              title="Embedded PDF"
                              src={URL.createObjectURL(formik.values[ele.id])}
                              style={{
                                width: "100%",
                                height: "50vh",
                                border: "none",
                                overflow: "hidden",
                              }}
                              frameBorder={0}
                              scrolling="no"
                            />
                          </div>
                        ) : (
                          ele.type === "file" &&
                          formik.values[ele.id] && (
                            <img
                              alt="Embedded Image"
                              src={URL.createObjectURL(formik.values[ele.id])}
                              style={{
                                width: "100%",
                                height: "50vh",
                                border: "none",
                                overflow: "hidden",
                              }}
                            />
                          )
                        )}
                      </div>
                    ) : updateData &&
                      updateData.paperDrivers &&
                      updateData.paperDrivers[index] &&
                      updateData.paperDrivers[index].elementsPapers &&
                      updateData.paperDrivers[index].elementsPapers[ind] ? (
                      <div>
                        {getFileExtension(
                          updateData?.paperDrivers[index]?.elementsPapers[ind]
                            ?.name
                        ) === "pdf" ||
                        getFileExtension(
                          updateData?.paperDrivers[index]?.elementsPapers[ind]
                            ?.name
                        ) === "docx" ? (
                          <div style={{ overflow: "hidden" }}>
                            <iframe
                              title="Embedded PDF"
                              src={`https://docs.google.com/gview?url=${process.env.REACT_APP_API_URL_VTCAP}/${updateData?.paperDrivers[index]?.elementsPapers[ind]?.name}&embedded=true`}
                              style={{
                                width: "100%",
                                height: "50vh",
                                border: "none",
                                overflow: "hidden",
                              }}
                              frameBorder={0}
                              scrolling="no"
                            />
                          </div>
                        ) : (ele.type === "file" &&
                            getFileExtension(
                              updateData?.paperDrivers[index]?.elementsPapers[
                                ind
                              ]?.name
                            ) === "png") ||
                          getFileExtension(
                            updateData?.paperDrivers[index]?.elementsPapers[ind]
                              ?.name
                          ) === "jpeg" ||
                          getFileExtension(
                            updateData?.paperDrivers[index]?.elementsPapers[ind]
                              ?.name
                          ) === "jpg" ||
                          getFileExtension(
                            updateData?.paperDrivers[index]?.elementsPapers[ind]
                              ?.name
                          ) === "gif" ? (
                          <img
                            alt="Embedded Image"
                            src={`${process.env.REACT_APP_API_URL_VTCAP}/${updateData?.paperDrivers[index]?.elementsPapers[ind]?.name}`}
                            style={{
                              width: "100%",
                              height: "50vh",
                              border: "none",
                              overflow: "hidden",
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </div>
            </Accordion>
          </div>
        ))}
    </div>
  );
};

export default Form2;
