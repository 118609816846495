import React from "react";
import { Outlet } from "react-router-dom";
import Tab from "../partial/Tab";


const SignUp = () => {
  return (
    <div className="container">
      <Tab/>
      <Outlet/>
      
    </div>
  );
};

export default SignUp;
