import React, { useState } from "react";
import LabelInput from "../form/LabelInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import clsx from "clsx";

const Login = () => {
  const [loading, setLoading] = useState(false);

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
  });

  const initialValues = {
    email: "x@gmail.com",
    password: "azerty",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      //   try {
      //     const { data: auth } = await login(values.email, values.password);
      //     saveAuth(auth);
      //     const { data } = await getUserByToken(auth.token);
      //     setCurrentUser(data);
      //     setCurrentUser2(data);
      //   } catch (error: any) {
      //     console.error(error);
      //     console.log(error.response.data.message);
      //     saveAuth(undefined);
      //     setStatus(error.response.data.message);
      //     setSubmitting(false);
      //     setLoading(false);
      //   }
    },
  });
  return (
    <form
      style={{ height: "100vh" }}
      className="form  d-flex align-items-center justify-content-center flex-column"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-3">
        <h1 className="text-dark  mb-3" style={{"fontFamily": 'Poppins',"fontWeight": "700"}}>Sign In</h1>
        <div className="text-gray-500 fw-semibold fs-6" style={{"fontFamily": 'Poppins'}}>
          Your Social Campaigns
        </div>
      </div>

      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      <div className=" w-25 mb-2">
        <LabelInput
          label={"email"}
          type={"email"}
          formik={formik}
          name={"nom"}
        />
      </div>
      <div className="fv-row mb-3 w-25">
        <LabelInput
          label={"Mot de passe"}
          type={"password"}
          formik={formik}
          name={"password"}
        />
      </div>

      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold justify-content-end mb-8">
        <Link to="/auth/forgot-password" className="fs-6 text-end mb-3 fw-500"  style={{"fontFamily": 'Poppins'}}>
          Forgot Password ?
        </Link>
      </div>

      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-outline-primary mb-3"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>

      <div className="text-gray-500 text-center fw-semibold fs-6">
        Not a Member yet?{" "}
        <Link to="/auth/registration" className="link-primary">
          Sign up
        </Link>
      </div>
    </form>
  );
};

export default Login;
