import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../app/components/login/Login";
import SignUp from "../app/components/login/signUp/SignUp";
import FromDriver from "../app/components/login/driver/FormDriver";

import FormRider from "../app/components/login/rider/FormRider";
import FormFleet from "../app/components/login/fleet/FormFleet";
import FormOrganization from "../app/components/login/organization/FormOrganization";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Login />} />
        <Route path="auth/registration" element={<SignUp />}>
          <Route path="driver" element={<FromDriver />} />
          <Route path="rider" element={<FormRider />} />
          <Route path="organization" element={<FormOrganization />} />
          <Route path="fleet" element={<FormFleet />} />
          <Route index element={<FromDriver />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
