import React, {createContext, useContext, ReactNode, useState} from 'react'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import {emitKeypressEvents} from 'readline'

// Define the type for your context value
interface AppContextValue {
  mydate: string
}

// Create a new context with the correct type
export const AppContext = createContext<AppContextValue | undefined | any>(undefined)

// Update the type for children prop
interface AppProviderProps {
  children: ReactNode
}

// Create a context provider component
export const AppProvider: React.FC<AppProviderProps> = ({children}) => {
  const [currentUser2, setCurrentUser2] = useState<any>()
  // console.log(currentUser2)

  const [progress, setProgress] = useState(0)

  const errorToas = (message: string) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
  }
  const successToas = (message: string) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
  }
  const deleteAlert = () => {
    return Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result:any) => {
      if (result.isConfirmed) {
        console.log('true')
        return true
      } else {
        return false
      }
    })
  }
  const hasPermission = (permission: any) => {
    if(currentUser2){

      return currentUser2.role.permissions.find((ele: any) => ele.name === permission) ? true : false
    }
  }
  const hasPermissionRoute = (permission: any) => {
  if(currentUser2){
    return currentUser2.role.routes.find((ele: any) => ele.name === permission) ? true : false
  }
  }
  const [myFilter, setFilter] = useState({filterBy: '', filterValue: ''})
  const value: any = {
    hasPermissionRoute,
    setCurrentUser2,
    setFilter,
    myFilter,
    errorToas,
    successToas,
    deleteAlert,
    hasPermission,
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
