import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import Spinner from "../../spinners/Spinner";
import { UseCallApi } from "../../../helpers/CallApi";
import Form1 from "./Form1";
import { AppContext } from "../../../../AppContext";
import Form2 from "./Form2";

const nameValidation = /^[A-Za-z\s]+$/;
const editUserSchema = Yup.object().shape({
  tel: Yup.string().required("Le numéro de téléphone est requis"),
  nom: Yup.string()
    .matches(nameValidation, "Le nom ne peut contenir que des lettres")
    .required("Le nom est requis"),
  prenom: Yup.string()
    .matches(nameValidation, "Le prénom ne peut contenir que des lettres")
    .required("Le prénom est requis"),
  sexe: Yup.string().required("Veuillez sélectionner le sexe"),
  email: Yup.string()
    .email("Format d'email incorrect")
    .required("L'email est requis"),
  password: Yup.string()
    .min(6, "Minimum 6 caractères")
    .required("Le mot de passe est requis"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), undefined],
      "Les mots de passe doivent correspondre"
    )
    .required("La confirmation du mot de passe est requise"),
  adress: Yup.string().required("L'adresse est requise"),
  zipCode: Yup.string().required("Le code postal est requis"),

  state: Yup.string().required("Le code postal est requis"),
  city: Yup.string().required("Le code postal est requis"),
  company: Yup.string().required("Le nom de l'entreprise est requis"),
  // paymentEmail: Yup.string()
  //   .email("Format d'email incorrect")
  //   .required("L'email de paiement est requis"),
  // AccountHolderName: Yup.string().required('Le nom du titulaire du compte est requis'),
  // AccountNumber: Yup.string().required('Le numéro de compte est requis'),
  // BankName: Yup.string().required('Le nom de la banque est requis'),
  // BankLocation: Yup.string().required("L'emplacement de la banque est requis"),
  // BIC_SWIFT: Yup.string().required('Le code BIC/SWIFT est requis'),
});
function FormFleet() {
  const [currentStep, setCurrentStep] = useState(1); // Step 1 by default
  const [image, setImage] = useState<File | any>();
  const [paperDrivers, setpaperDrivers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formDoc, setFormDoc] = useState<any>([]);
  const [filess, setFiles] = useState<any>();
  const { successToas, errorToas } = useContext(AppContext);
  const callApi = UseCallApi();

  const [uploadedFiles, setUploadedFiles] = useState<
    Record<string, File | null>
  >({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tel: "",
      nom: "",
      prenom: "",
      email: "",
      password: "",
      confirmPassword: "",
      sexe: "",
      country: "",
      state: "",
      city: "",
      adress: "",
      zipCode: "",
      company: "",
      language: "",
      currency: "",
      paymentEmail: "",
      AccountHolderName: "",
      AccountNumber: "",
      BankName: "",
      BankLocation: "",
      BIC_SWIFT: "",
    },
    validationSchema: editUserSchema,
    onSubmit: async (values: any, { setSubmitting }) => {
      setSubmitting(true);
      setIsLoading(true);
      const postData = {
        password: values.password,
        email: values.email,
        roles: ["ROLE_PASSAGER"],
        typeAccount: "PASSAGER",
        photo: image ? image[0] : null,

        passager: {
          // company: `api/companies/${values.company}`,
          company: `api/companies/1`,
          pays: `api/pays/${values.country}`,
          city: `api/cities/${values.city}`,
          language: `api/languages/${values.language}`,
          state: `api/states/${values.state}`,
          bic_swift: values.BIC_SWIFT,
          bankName: values.BankName,
          accountNumber: values.AccountNumber,
          accountHolderName: values.AccountHolderName,
          paymentEmail: values.paymentEmail,
          zipCode: Number(values.zipCode),
          bankLocation: values.BankLocation,
          adress: values.adress,
          // state: values.state,
          // state: JSON.parse('true'),
          // dispo: JSON.parse('false'),
          password: values.password,
          tel: values.tel,
          name: values.nom,
          fulname: `${values.prenom} ${values.nom}`,
          email: values.email,
          ...paperDrivers.reduce((acc: any, obj: any) => {
            obj.elementsPapers.forEach((element: any) => {
              const key = element.name;
              if (values[key]) {
                acc[element.name] = values[key];
              }
            });
            return acc;
          }, {}),
          sexe: values.sexe,
          paperDrivers,
          createdAt: "2023-08-15T11:15:16.614Z",
          updatedAt: "2023-08-15T11:15:16.614Z",
          lastLogin: "string",
        },
      };
      try {
        const postDriver = async () => {
          try {
            const data = await callApi({
              route: "api/users",
              method: "POST",
              body: postData,
              formData: true,
            });
            if (data) {
              successToas("success");
              // navigate('/apps/user-management/users')
            }
          } catch (error: any) {
            errorToas(error.response["data"].detail);
            // setTimeout(() => setIsLoading(false), 1000);
          }
        };
        postDriver();
      } catch (ex) {
        console.error(ex);
      } finally {
        setIsLoading(false);

        setSubmitting(false);
      }
    },
  });

  const getForm = async () => {
    try {
      const data = await UseCallApi()({
        route: `api/form_model_documents?&model=passagers&pays=${
          formik.getFieldProps("country").value
        }`,
        method: "GET",
        jsonLd: true,
      });
      const form = data["hydra:member"][0].formDocuments;

      setFormDoc(form);
      setpaperDrivers([]);
      let elementsPapers: any = [];
      let objects: any = [];
      for (let index = 0; index < form.length; index++) {
        const elements = form[index].elementFormDocuments;

        const title = form[index].title;

        elementsPapers = [];
        for (
          let secondIndex = 0;
          secondIndex < elements.length;
          secondIndex++
        ) {
          const element = elements[secondIndex];

          const obj = {
            label: element.label,
            name: element.id,
          };

          elementsPapers.push(obj);
        }

        objects.push({ title, state: "state", elementsPapers });
      }

      setpaperDrivers(objects);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  useEffect(() => {
    getForm();
  }, [formik.getFieldProps("country").value]);
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleFileUpload = (title: string, file: File | null) => {
    setUploadedFiles((prevState) => ({
      ...prevState,
      [title]: file,
    }));
  };

  return (
    <div>
      {" "}
      <div className="text-center pt-15 mb-5">
        {currentStep === 1 && (
          <Form1
            formik={formik}
            isUserLoading={isLoading}
            setImage={setImage}
            image={image}
            setFiles={setFiles}
            filess={filess}
          />
        )}
        {currentStep === 2 && <Form2 form={formDoc} formik={formik} />}
        {currentStep > 1 && (
          <button
            type="button"
            className="btn btn-light me-3"
            onClick={handlePreviousStep}
            disabled={formik.isSubmitting || isLoading}
          >
            Précédente
          </button>
        )}
        {currentStep < 2 && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleNextStep}
            disabled={
              formik.isSubmitting || isLoading || !formik.isValid ? true : false
            }
          >
            Suivant
          </button>
        )}
        {currentStep === 2 && (
          <>
            {isLoading ? (
              <button
                type="button"
                className="btn btn-primary"
                disabled={true}
                aria-readonly
              >
                <Spinner />
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary"
                onClick={(e: any) => {
                  formik.handleSubmit(e);
                  setIsLoading(true);
                }}
                disabled={formik.isSubmitting || !formik.isValid ? true : false}
              >
                Submit
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default FormFleet;
