import React from 'react'
import Select from 'react-select'
import clsx from 'clsx'
type Props = {
  options?: any
  defaultValue?: any
  name: string
  formik?: any
}

const SearchSelect: React.FC<Props> = ({options, defaultValue, name, formik}) => {
  const optionsWithNone = [{value: 'null', label: 'None'}, ...options]
  return (
    <div>
      <Select
        value={options.filter((option:any) => option.value == defaultValue)}
        options={optionsWithNone}
        onChange={(selectedOption) => {
          formik.setFieldValue(name, selectedOption.value)
        }}
        className={clsx(
          {'is-invalid': formik.touched[name] && formik.errors[name]},
          {
            'is-valid': formik.touched[name] && !formik.errors[name],
          }
        )}
      
      />
      {formik.touched[name] && formik.errors[name] && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors[name]}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchSelect
